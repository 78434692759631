@use '@angular/material' as mat;

$pace-color: #fbb03b; // rgb(255, 153, 0);
.pace .pace-progress {
  background: $pace-color;
}

.pace .pace-activity {
  border-top-color: $pace-color;
  border-left-color: $pace-color;
}

.pace .pace-progress-inner {
  box-shadow: 0 0 10px $pace-color, 0 0 5px $pace-color;
}

/**
    * Switchery.
    */

.switch {
  box-sizing: content-box;
}

.switch input {
  display: none;
}

.switch i {
  display: inline-block;
  cursor: pointer;
  padding-right: 20px;
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  border-radius: 20px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}

.switch i:before {
  display: block;
  content: "";
  width: 30px;
  height: 30px;
  padding: 1px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.switch :checked + i {
  padding-right: 0;
  padding-left: 20px;
  background: rgb(100, 189, 99); // background: #F2BE35;
}

.switch span {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  line-height: 32px;
}

/**
    * Freshdesk widget
    */

#freshwidget-button {
  left: inherit !important;
  right: 350px !important;
}

.cc-window {
  z-index: 999999 !important;
}

.cc-message {
  line-height: 1.2;
}

.cc-compliance {
  padding-left: 2em;
}

.tooltip .tooltip-inner {
  max-width: 100%;
}

.transit-error-handling-snackbar {
  .mat-button-wrapper {
    color: #ff4081
  }
}

// material_custom_theme
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$brand-palette-primary: (50: #ebf6fc, 100: #cce9f8, 200: #abdbf4, 300: #89cdf0, 400: #6fc2ec, 500: #56b7e9, 600: #4fb0e6, 700: #45a7e3, 800: #3c9fdf, 900: #2b90d9, A100: #ffffff, A200: #e6f4ff, A400: #b3deff, A700: #9ad2ff, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #000000, 500: #000000, 600: #000000, 700: #000000, 800: #000000, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #000000,));
$brand-palette-danger: (50: #fceae1, 100: #f8cbb4, 200: #f3a982, 300: #ee864f, 400: #eb6c2a, 500: #e75204, 600: #e44b03, 700: #e04103, 800: #dd3802, 900: #d72801, A100: #fffefe, A200: #ffd2cb, A400: #ffa598, A700: #ff8f7f, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #000000, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #000000,));
$brand-palette-accent: (50: #e0e6eb, 100: #b3bfcc, 200: #8095aa, 300: #4d6b88, 400: #264b6f, 500: #002b55, 600: #00264e, 700: #002044, 800: #001a3b, 900: #00102a, A100: #648bff, A200: #3165ff, A400: #003ffd, A700: #0039e4, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #ffffff, A400: #ffffff, A700: #ffffff,));

$brand-app-primary: mat.define-palette($brand-palette-primary);
$brand-app-accent: mat.define-palette($brand-palette-accent);
$brand-app-warn: mat.define-palette($brand-palette-danger);
$brand-app-theme: mat.define-light-theme((
  color: (
    primary: $brand-app-primary,
    accent: $brand-app-accent,
    warn: $brand-app-warn))
);

@include mat.all-legacy-component-themes($brand-app-theme);

.form-input {
  color: mat.get-color-from-palette($brand-app-accent)
}

// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 300;
}

.h4, .h5, .h6, h4, h5, h6 {
  margin-top: 10px;
}

@mixin thumb($parent, $size) {
  #{$parent} {
    width: $size;

    > .fa {
      line-height: $size;
    }
  }
}

@include thumb('.thumb-xlg', 100px);
@include thumb('.thumb-lg', 60px);
@include thumb('.thumb', 48px);
@include thumb('.thumb-sm', 34px);
@include thumb('.thumb-xs', 28px);

.thumb-xs,
.thumb-sm,
.thumb-lg,
.thumb-xlg,
.thumb {
  position: relative;
  display: inline-block;
  text-align: center;

  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }
}
