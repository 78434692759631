mat-divider {
  color: #e0e2ec;
}

mat-datepicker-content {
  box-shadow:
          0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: block;
  border-radius: 4px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);

  .mat-calendar-body-cell {
    .mat-calendar-body-cell-content {
      border: none;

      &:hover {
        background: #e3e6e9;
      }
    }

    &:focus, &.mat-calendar-body-active {
      outline: none;

      .mat-calendar-body-cell-content {
        border: 2px solid #003369;
      }
    }

  }
}
