@import 'pace/themes/orange/pace-theme-flash.css';
@import 'mapbox-gl/dist/mapbox-gl.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'ol/ol.css';
@import 'katex/dist/katex.min.css';
@import 'github-markdown-css/github-markdown.css';
@import './variables_overrides';
@import './primeng_custom_theme';
@import './custom';
@import "./global";

html, body {
  height: 100%;
  font-size: 14px;
  font-family: 'Open Sans', Arial, monospace;
}

body {
  font-weight: 300;
  overflow-x: hidden;
}

app, .app {
  height: 100%;
  display: block;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url(/assets/fonts/google/open-sans-light.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'),
  url(/assets/fonts/google/open-sans.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
  url(/assets/fonts/google/open-sans-semibold.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url(/assets/fonts/google/open-sans-bold.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
  url(/assets/fonts/google/open-sans-light-italic.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url(/assets/fonts/google/open-sans-italic.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'),
  url(/assets/fonts/google/open-sans-semibold-italic.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url(/assets/fonts/google/open-sans-bold-italic.woff) format('woff');
}
